import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { SimpleConfiguration } from "./SimpleConfiguration";
import { ApplicationConfigurationFormProps } from "../../../../types";
import { SzInput } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";

type ApplicationParameters = {
    codeCollectivite: string,
    codeBanco: string
};


const serviceName = "REQUEST_RT_PROX_CITE";

export const REQUEST_RT_PROX_CITE = (props: ApplicationConfigurationFormProps) => {
    const { serviceConfigurations, setServiceConfigurations, owner, ownerType } = props;
    const { t } = useTranslation();
    const [codeCollectivite, setCodeCollectivite] = useState<string>("undefined" !== typeof serviceConfigurations[serviceName] ? serviceConfigurations[serviceName].parameters?.codeCollectivite ?? "" : "");
    const [codeBanco, setCodeBanco] = useState<string>("undefined" !== typeof serviceConfigurations[serviceName] ? serviceConfigurations[serviceName].parameters?.codeBanco ?? "" : "");

    const parameters = useRef<ApplicationParameters>({
        codeCollectivite: codeCollectivite,
        codeBanco: codeBanco
    });

    useEffect(() => {
        parameters.current = {...parameters.current, codeCollectivite, codeBanco }
            if (
                "undefined" === typeof serviceConfigurations[serviceName] ||
                "undefined" !== typeof serviceConfigurations[serviceName] &&
                (
                    parameters.current.codeCollectivite !== serviceConfigurations[serviceName].parameters?.codeCollectivite ||
                    parameters.current.codeBanco !== serviceConfigurations[serviceName].parameters?.codeBanco
                )
            ) {
                setServiceConfigurations({
                    ...serviceConfigurations, [serviceName]: {
                        displayButtonForceChildConfiguration: true,
                        name: serviceName,
                        owner: owner,
                        ownerType: ownerType,
                        parameters: {
                            codeCollectivite: parameters.current.codeCollectivite,
                            codeBanco: parameters.current.codeBanco
                        }
                    }
                });
            }
    }, [codeCollectivite, codeBanco]);

    return <div>
        <SzInput
            value={codeCollectivite}
            name={`${serviceName}.codeCollectivite`}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setCodeCollectivite(e.target.value)}
            label={t('admin.services.configuration.form.codeCollectivite')}
            required={true}
        />
        <SzInput
            value={codeBanco}
            name={`${serviceName}.codeBanco`}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setCodeBanco(e.target.value)}
            label={t('admin.services.configuration.form.codeBanco')}
            required={true}
        />
    </div>
}
