import { currentContract, login, logout } from '../actions';
import { ApiUrls, AppUrls } from '../constants';
import qs from "qs";
import axiosService from './Request';
import { store } from '../store';
import { config } from "../config";
import { UserContract } from "../types";
import { ContractsService } from "./ContractsService";
import { ThemeService } from "./ThemeService";
import { UserConfigurationService } from './UserConfigurationService';

export class AuthenticateService {
    public static async login() {
        const { token } = qs.parse(window.location.search.substring(1));
        if (!token) {
            const realm = await this.getRealm(window.location.origin);
            localStorage.setItem("redirect-portal-extranet", window.location.href);
            localStorage.setItem("realm", realm);
            window.location = `${AppUrls.SAML_LOGIN}-${realm}`;
        } else {
            localStorage.setItem(config.tokenKey, token.toString());
            let redirectHref = localStorage.getItem("redirect-portal-extranet");
            localStorage.removeItem("redirect-portal-extranet");
            await this.authenticate()
            window.location = redirectHref ? redirectHref as any : AppUrls.HOME;
        }
    }

    public static async logout() {
        const realm = localStorage.getItem('realm') ?? await this.getRealm(window.location.origin);
        localStorage.clear();
        store.dispatch(logout());
        window.location = `${AppUrls.SAML_LOGOUT}-${realm}`;
    }

    public static async authenticate() {
        axiosService.get(ApiUrls.TOKEN_INFO_URL, false)
            .then(response => store.dispatch(login(response.data)));

        let currentConfig = await UserConfigurationService.getCurrentConfigurationContract();

        // at this step current config could contain a contract with outdated metadata, we ensure it's up to date.
        let contracts: UserContract[] = await ContractsService.getContractsByUser().then((response) => response.data);
        if (currentConfig && contracts.some(contract => contract.id === currentConfig.id)) {
            const { contractMetadatas } = await ContractsService.getContractById(currentConfig.id);
            const adaptedContractMetadatas = Object.entries(contractMetadatas)
                .map((contractMetadata) => ({
                    key: contractMetadata[0], value: contractMetadata[1],
                }));

            this.setCurrentContract({
                ...currentConfig,
                contractMetadatas: adaptedContractMetadatas,
            });
        } else {
            let firstContract = this.getFirstContract(contracts);
            if (firstContract) {
                this.setCurrentContract(firstContract);
                UserConfigurationService.storeCurrentContractConfiguration(firstContract);
            }
        }
    }

    private static getFirstContract(contracts: UserContract[]): UserContract|undefined {
        if (contracts.length > 0) {
            return contracts.slice().shift();
        }
    }

    private static setCurrentContract(contract: UserContract): void {
        ThemeService.setTheme(contract);
        store.dispatch(currentContract(contract));
    }

    private static async getRealm(domainName: string): Promise<string>  {
        const response = await axiosService.get(`${ApiUrls.PUBLIC_REALMS}?domainName=${domainName}`, false);

        return response.data.value;
    }
}
