import React from "react"
import { SzButton } from "@suezenv/react-theme-components";
import { SzButtonProps } from "@suezenv/react-theme-components/build/components/SzButton/SzButton";

const AddButton = (props: {
    handleClick: () => void,
    className?: string,
    isDisabled?: boolean,
    variant?: SzButtonProps['variant']
}) => {

    let variant = props.variant ? props.variant : 'primary';

    return <SzButton variant={variant} icon='add-square' isDisabled={props.isDisabled} className={`color-success ${props.className}`}
            onClick={props.handleClick}
    ></SzButton>
}


export default AddButton