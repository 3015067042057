export enum ActionTypes {
    GET_USER_CONFIGURATION = "GET_USER_CONFIGURATION",
    SET_USER_CONFIGURATION = "SET_USER_CONFIGURATION",
}

export const MONTH_FR = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']

export const DAYS_FR = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche']
export const DAYS_SHORT_FR = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim']
export const DAYS_EN = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

export enum Themes {
    ONDIJON = 'onDijon',
    VILLAGILE = 'villagile',
}

export enum Action {
    INDUSTRIAL_SITE = 'INDUSTRIAL_SITE'
}

export enum Constants {
    ALL_ITEMS_PER_PAGE = 9999,
    BASE64_PREFIX = 'data:image/jpeg;base64,',
    CONTRACTS_PAGINATION_PAGE_RANGE = 5,
    CURRENT_CONTRACT = 'current_contract',
    DATA_LOADING = "DATA_LOADING",
    DISPLAYED_WIDGETS_CONFIGURATION_CODE = "displayed_widgets",
    DEFAULT_CURRENT_PAGE = 1,
    DEFAULT_PER_PAGE = 20,
    DEFAULT_COUNTRY_CODE = 'FR',
    ERROR = 'ERROR',
    ERROR_INTERVENTION = "ERROR_INTERVENTION",
    HYDRO_WIDGET_INTERVAL = 4,
    HYDRO_WIDGET_MAX = 12,
    INDUSTRIAL_SITE_CONFIGURATION_CODE= "industrial_site_configuration_code",
    INDUSTRIAL_SITE_CAROUSEL_HEIGHT = "360px",
    INDUSTRIAL_SITE_PORTAL_SERVICE_CODE= "INDUSTRIAL_SITE",
    INTERVENTION_SEARCH = 'intervention_search',
    LOG_IN = "login",
    LOG_OUT = "logout",
    NOTIFICATIONS_PER_PAGE = 20,
    PORTAL_CONFIGURATION_CODE = "portal_configuration",
    PORTAL_NAME = "ZEUS",
    PRIMARY_LOGO = "primary_logo",
    PRIMARY_COLOR = "primary_color",
    SECONDARY_LOGO = "secondary_logo",
    SECONDARY_COLOR = "secondary_color",
    SIGNAL_TOP_CATEGORY_LENGTH = 5,
    UI_SHOW_MODAL = "ui_show_modal",
    UI_HIDE_MODAL = "ui_hide_modal",
    USER_PERMISSIONS = "user_permissions",
    USERS_PAGINATION_PAGE_RANGE = 5,
    USER_SKILLS = "user_skills",
    WIDGETS_GRID_LAYOUT_CONFIGURATION_CODE = "widgets_grid_layout"
}

export enum Pagination {
    PAGINATION_TOTAL_COUNT = 'x-pagination-total-count',
    PAGINATION_PAGE_COUNT = 'x-pagination-page-count',
    PAGINATION_CURRENT_PAGE = 'x-pagination-current-page',
    PAGINATION_PER_PAGE = 'x-pagination-per-page'
}

// @deprecated use constants/map.ts
export enum Map {
    BOUNDS_FRANCE = "[[42.252918, -5.009766], [51.193115, 8.481445]]",
    DEFAULT_LAT = 47.322047,
    DEFAULT_LNG = 5.04148,
    OSM_URI = 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
    ZOOM_DEFAULT = 6,
    ZOOM_MIN = 6,
    ZOOM_MAX = 18
}

export enum Roles {
    ROLE_ITV = 'ROLE_ITV',
    ROLE_HYDRO = 'ROLE_HYDRO',
    ROLE_OPOT = 'ROLE_OPOT',
    ROLE_USER = 'ROLE_USER',
    ROLE_SUPER_ADMIN_CONTRACT = 'ROLE_SUPER_ADMIN_CONTRACT',
    ROLE_SUPER_ADMIN_ORGANIZATION = 'ROLE_SUPER_ADMIN_ORGANIZATION',
    ROLE_SUPER_ADMIN_PORTAL_VILLAGILE = 'ROLE_SUPER_ADMIN_PORTAL_ZEUS',
    ROLE_SUPER_ADMIN_PORTAL_OPEN_API = 'ROLE_SUPER_ADMIN_PORTAL_OPEN_API',
    ROLE_SUPER_ADMIN_PORTAL_THINGS_BOARD = 'ROLE_SUPER_ADMIN_PORTAL_THINGS_BOARD',
    ROLE_ZEUS_SIGNAL = 'ROLE_ZEUS_SIGNAL',
    ROLE_SIGNAL_NOTIFY = 'ROLE_SIGNAL_NOTIFY',
    ROLE_SIGNAL_OPERATOR = 'ROLE_SIGNAL_OPERATOR',
    ROLE_PORTAL_VDM = 'ROLE_PORTAL_VDM',
    ROLE_VA_GEST_DOC = 'ROLE_VA_GEST_DOC',
    ROLE_REQUEST = 'ROLE_REQUEST',
    ROLE_REQUEST_OPERATOR = 'ROLE_REQUEST_OPERATOR',
    SUPER_ADMIN = 'SUPER_ADMIN',
    ROLE_PUBLIC_SPACE_PLANNER = 'ROLE_PUBLIC_SPACE_PLANNER',
    ROLE_REPORT_TRENDS = 'ROLE_REPORT_TRENDS',
    ROLE_POWER_BI = 'ROLE_POWER_BI',
    ROLE_THINGS_BOARD = 'ROLE_THINGS_BOARD',
    ROLE_INDUSTRIAL_SITE  = 'ROLE_INDUSTRIAL_SITE',
    ROLE_CALENDAR = 'ROLE_CALENDAR',
    ROLE_CALENDAR_ADMIN = 'ROLE_CALENDAR_ADMIN'
}

export type GiveableRoles = Roles.ROLE_USER | Roles.ROLE_SUPER_ADMIN_ORGANIZATION | Roles.ROLE_SUPER_ADMIN_CONTRACT;

export enum Families {
    HYDROMETRIC = 'hydrometric',
    INTERVENTIONS = 'interventions',
    WATER_CYCLE = 'waterCycle',
    SIGNAL = 'signal',
    DOCUMENT= 'document',
    PUBLIC_SPACE_PLANNER='travaux',
    REQUEST_STATUS='request'
}

export enum widgetTypes {
    HYDROMETRIC = "hydrometric",
    INTERVENTIONS = 'interventions',
    SIGNAL_STAT_CONDENSED = 'signalStatCondensed',
    SIGNAL_STAT_FULL = 'SignalStatFull',
    SIGNAL_TOP_CATEGORY = 'SignalTopCategory',
    WATER_CYCLE = 'waterCycle',
    WATER_CONFORMITY = 'waterConformity',
    DOCUMENT_NOTIFICATION = 'DocumentNotifications',
    LAST_DOCUMENTS = 'LastDocuments',
    NUMBER_DOCUMENTS = 'NumberDocuments',
    PUBLIC_SPACE_PLANNER_STAT_FULL = 'PublicSpacePlannerStatFull',
    REQUEST_STATUS = 'requestStatus',
}

export enum Services {
    CARTOGRAPHY = 'cartography',
    HYDROMETRIC = 'hydrometric',
    INTERVENTIONS = 'interventions',
    SIGNAL_NOTIFY = "SIGNAL_NOTIFY",
    WATER_CYCLE = 'waterCycle',
    WATER_CONFORMITY = 'waterConformity',
    VA_GEST_DOC = 'VA_GEST_DOC',
    PUBLIC_SPACE_PLANNER = "PUBLIC_SPACE_PLANNER",
    REQUEST = "REQUEST",
 }

export enum OrganizationTypes {
    COMMUNITY = 'Community',
    EXTERNAL_ENTITY = 'ExternalEntity',
    OPERATOR = 'Operator'
}

export enum HttpCode {
    NOT_FOUND = 404,
    ACCESS_DENIED = 401,
    OOPS = 500,
    UNSUPPORTED_MEDIA = 415
}

export enum AppUrls {
    ADMIN_CONTRACTS = '/admin/contrat',
    ADMIN_CONTRACTS_CREATE = '/admin/contrat/creation',
    ADMIN_CONTRACTS_CREATE_SUCCESS = '/admin/contrat/creation/validation',
    ADMIN_CONTRACTS_OWN = '/admin/contrat/mes-contrats',
    ADMIN_CONTRACTS_TOFINISH = '/admin/contrat/a-finaliser',
    ADMIN_CONTRACTS_DETAILS = '/admin/contrat/:contractId',
    ADMIN_GESTION_TERRITORIALE = '/gestion-territoriale',
    ADMIN_GESTION_TERRITORIALE_INDUSTRIAL_SITE_CREATE = '/gestion-territoriale/site-industriel/creation',
    ADMIN_GESTION_TERRITORIALE_INDUSTRIAL_SITE_EDIT = '/gestion-territoriale/site-industriel/:siteId',
    ADMIN_GESTION_TERRITORIALE_INDUSTRIAL_SITE_CONTACT_CREATE = '/gestion-territoriale/site-industriel/:siteId/contact',
    ADMIN_GESTION_TERRITORIALE_INDUSTRIAL_SITE_CONTACT_EDIT = '/gestion-territoriale/site-industriel/:siteId/contact/:contactId',
    CARTO = '/cartographie',
    CGU = '/conditions-generales-d-utilisation-portail',
    Cookies = '/declaration-cookies',
    CALENDAR_VIEW = '/calendrier',
    CALENDAR_VIEW_ID = '/calendrier/:calendrierId',
    CALENDAR_VIEW_EVENT = '/calendrier/:calendrierId/evenement',
    HELP = '/aide',
    HOME = '/',
    INDUSTRIAL_SITE_VIEW = '/mon-site',
    INDUSTRIAL_SITE_INDICATORS = '/mon-site#indicateurs',
    INDUSTRIAL_SITE_DETAILS = '/mon-site#details',
    LEGAL_POLICIES = '/mentions-legales-portail',
    LOGOUT = '/deconnexion',
    PUBLIC_WORK_LIST = '/travaux',
    PUBLIC_WORK_NEW = '/travaux/nouveau',
    PUBLIC_WORK_DETAIL = '/travaux/:workId',
    PUBLIC_WORK_EDIT = '/travaux/edition/:workId',
    POWER_BI_WORKSPACE_TRENDS = '/api/powerbi/trends',
    POWER_BI_WORKSPACE = '/api/powerbi',
    REQUEST_VIEW = '/demande',
    REQUEST_CHOICE = '/demande/nouvelle-demande',
    REQUEST_CREATE = '/demande/nouvelle-demande/:requestId',
    REQUEST_DRAFT = '/demande/nouvelle-demande/:requestId',
    REQUEST_DETAIL = '/demande/:requestId',
    SAML_LOGIN = '/saml/login',
    SAML_LOGOUT = 'saml/logout',
    SIGNAL_CREATE = '/signalement/signaler',
    SIGNAL_DETAIL = '/signalement/signal/:signalId',
    SIGNAL_VIEW = '/signalement',
    SIGNAL_TO_REQUEST_CREATE = '/demande/signal-vers-demande/:signalId',
    THINGS_BOARD_WORKSPACE = '/api/thingsboard/dashboards',
    THINGS_BOARD_USER_TOKEN = '/api/thingsboard/user-token',
    USER_PROFILE = '/mon-profil',
}

export enum TrackingService {
    ADMIN_CONTRACTS = 'ADMIN',
    ADMIN_CONTRACTS_CREATE = 'ADMIN',
    ADMIN_CONTRACTS_CREATE_SUCCESS = 'ADMIN',
    ADMIN_CONTRACTS_OWN = 'ADMIN',
    ADMIN_CONTRACTS_TOFINISH = 'ADMIN',
    ADMIN_CONTRACTS_DETAILS = 'ADMIN',
    ADMIN_GESTION_TERRITORIALE = 'ADMIN',
    CARTO = 'DASHBOARD',
    CGU = 'WEB_PAGE',
    HELP = 'WEB_PAGE',
    HOME = 'DASHBOARD',
    LEGAL_POLICIES = 'WEB_PAGE',
    LOGOUT = 'AUTHENTICATION',
    SIGNAL_CREATE = 'SIGNAL',
    SIGNAL_DETAIL = 'SIGNAL',
    SIGNAL_VIEW = 'SIGNAL',
    REQUEST_VIEW = 'REQUEST',
    REQUEST_CHOICE = 'REQUEST',
    REQUEST_CREATE = 'REQUEST',
    REQUEST_DRAFT = 'REQUEST',
    REQUEST_DETAIL = 'REQUEST',
    SAML_LOGIN = 'AUTHENTICATION',
    SAML_LOGOUT = 'AUTHENTICATION',
    USER_PROFILE = 'USER_PROFILE',
    PUBLIC_WORK_LIST = 'PUBLIC_WORK',
    PUBLIC_WORK_NEW = 'PUBLIC_WORK',
    PUBLIC_WORK_DETAIL = 'PUBLIC_WORK',
    PUBLIC_WORK_EDIT = 'PUBLIC_WORK'
}

// Contains ["/admin/contrat", "/admin/contrat/creation", "..."]
export const AppUrlsList: string[] = Object.entries(AppUrls).map(([key, value]) => ( value ));

export enum AppAdminDetailsUrls {
    AREA_DETAILS = "/area/:areaId",
    ORGANIZATION_DETAILS = "/organisation-territoriale/:organizationId",
    OPERATOR_DETAILS = "/exploitant/:operatorId",
    USER_DETAILS = "/utilisateur/:userId"
}

export enum adminTabsEventKeys {
    AREA_ORGANIZATIONS = "area-organizations",
    AREA_OPERATORS = "area-operators",
    AREA_SKILLS = "area-skills",
    CONTRACT_AREA = "contract-areas",
    CONTRACT_USERS = "contract-users",
    CONTRACT_ORGANIZATIONS = "contract-organizations",
    CONTRACT_OPERATOR = "contract-operator",
    CONTRACT_SKILLS = "contract-skills",
    CONTRACT_SERVICES = "contract-services",
    CONTRACT_INDUSTRIAL_SITES = "contract-industrial-sites",
    ORGANIZATION_USERS = "organization-users",
    ORGANIZATION_AREAS = "organization-areas",
    ORGANIZATION_SERVICES = "organization-services",
    USER_AREAS = "user-areas",
    USER_CONTRACTS = "user-contracts",
    USER_ORGANIZATIONS = "user-organizations",
    USER_OPERATORS = "user-operators",
}

export enum ErrorRoutes {
    NOT_FOUND = '/error/unknown',
    ACCESS_DENIED = '/error/access-denied',
    OOPS = '/error/oops',
    SSO_ERROR = '/error/sso'
}

export enum ApiUrls {
    AREA_CATEGORIES = '/api/contract/:contractId/area/:areaId/category',
    AREA_ORGANIZATIONS = '/api/contract/:contractId/area/:areaId/organization',
    AREA_AND_CONTRACT_AREA_URL = "/api/contract/:contractId/contractArea",
    AREAS_NB_CATEGORIES = '/api/contract/:contractId/areas/skillset',
    AREAS_NB_ORGANIZATION = '/api/contract/:contractId/areas/organization',
    HYDRO_URL = '/api/hydrometry',
    ITV_COUNT_URL = 'api/intervention-count',
    ITV_URL = 'api/interventions',
    LEGAL_ENTITIES_BY_CONTRACT = '/api/contract/:contractId/legalEntity',
    TOKEN_INFO_URL = '/api/token-check',
    REQUEST_TYPES = '/api/requestTypes',
    MUNICIPALITIES = '/api/municipalities',
    ADMINISTRATIVE_DIVISIONS = '/api/administrativeDivision',
    UPDATE_ORGANIZATION_SERVICES = '/api/organization/:organizationId/organization_contract_service',
    PERMISSIONS_ADMIN = '/api/permissions/admin',
    PERMISSIONS_USER = '/api/permissions/user',
    SERVICE = '/api/service',
    SIGNAL_STATS = '/api/widget/signal/stats',
    REQUEST_STATS = '/api/widget/request/stats',
    SIGNAL_TOP_CATEGORY = '/api/widget/signal/top-category/:length',
    SKILLSETS = '/api/category',
    WATER_QUALITY_URL = 'api/water-quality',
    WATER_CYCLE_URL = 'api/water-cycle',
    WEATHER_URL = 'api/weather',
    DOCUMENT_NOTIFICATIONS = 'api/widget/document/notifications',
    LAST_DOCUMENTS = 'api/widget/document/last/created',
    GET_DOCUMENTS = 'api/widget/document',
    PUBLIC_SPACE_PLANNER_STATS = '/api/public-work/report/stats',
    PUBLIC_REALMS = '/api/public/realms',
    REQUEST_STATUSES_FROM_CONTRACT = '/api/contract/:contractId/request/getAllStatus',
    // APPLICATION
    APPLICATION_CONFIGURATION_CREATE = '/api/external-apps/configuration',
    APPLICATION_CONFIGURATION_GET_BY_OWNER_TYPE = '/api/external-apps/configuration/:ownerType/:ownerId',
    APPLICATION_CONFIGURATION_GET_BY_NAME_OWNER_TYPE_OWNER_ID = '/api/external-apps/configuration/application/:applicationName/:ownerType/:ownerId',
    // CALENDAR
    CALENDAR_URL = '/api/calendar/object/:objectId/calendar',
    CALENDAR_EVENTS_URL = '/api/calendar/:calendarId/event/startDate/:startDate/endDate/:endDate',
    CALENDAR_EVENT_URL = '/api/calendar/:calendarId/event',
    CALENDAR_EVENT_CATEGORIES_URL = '/api/calendar/referential/event-category',
    CALENDAR_EVENT_CATEGORY_URL = '/api/calendar/referential/event-category/:eventCategoryId',
    // CONFIGURATION
    PORTAL_CONFIGURATION = '/api/portalconfiguration/:siteCode',
    SITE_CONFIGURATION = '/api/contract/:contractId/siteConfiguration',
    // CONTRACT
    CONTRACT = '/api/contract',
    CONTRACT_BY_ID = '/api/contract/:contractId',
    CONTRACT_AREAS = '/api/contract/:contractId/areas',
    CONTRACT_AREA = '/api/contract/:contractId/area/:areaId',
    CONTRACT_USER_URL = '/api/contract/:contractId/user/:userId',
    CONTRACTS = '/api/contracts',
    CONTRACTS_BY_USER = '/api/user/contracts',
    CONTRACT_BASEMAP = 'api/contract-basemap',
    CONTRACT_INFO_URL = 'api/contract-info',
    CONTRACT_SERVICE = '/api/contract/:contractId/contractService',
    CONTRACT_SERVICE_DELETE = '/api/contractService/:contractServiceId',
    // INDUSTRIAL SITES
    INDUSTRIAL_SITES_URL =  '/api/industrial-site',
    INDUSTRIAL_SITE_URL =  '/api/industrial-site/:siteId',
    INDUSTRIAL_SITE_PHOTO_URL =  '/api/industrial-site/:siteId/photo/:photoId',
    INDUSTRIAL_SITE_CONTACT_URL =  '/api/industrial-site/:siteId/contact',
    INDUSTRIAL_SITE_CONTACT_EDIT_URL =  '/api/industrial-site/:siteId/contact/:contactId',
    INDUSTRIAL_SITE_PHOTO_CONTENT_URL =  '/api/industrial-site/photo/:photoId/binary',
    INDUSTRIAL_SITE_POST_PHOTO_URL =  '/api/industrial-site/:siteId/photo',
    INDUSTRIAL_SITE_INDICATOR_VALUES_URL =  '/api/industrial-site/:siteId/indicator-value',
    INDUSTRIAL_SITE_INDICATOR_SOURCES_URL =  '/api/indicator-source',
    INDUSTRIAL_SITE_METADATA_URL =  'api/industrial-site/:siteId/metadata',
    INDUSTRIAL_SITE_METADATA_DELETE_URL =  'api/industrial-site/:siteId/metadata/:metadataId',
    // ORGANIZATIONS
    ORGANIZATIONS = '/api/contract/:contractId/organization',
    ORGANIZATION = '/api/organization/:organizationId',
    ORGANIZATION_SERVICES = '/api/organization/:organizationId/service',
    ORGANIZATION_SERVICE_DELETE = '/api/organization/:organizationId/service/:serviceCode',
    ORGANIZATION_USERS = '/api/organization/:organizationId/user',
    ORGANIZATION_USER = '/api/organization/:organizationId/user/:userId',
    ORGANIZATION_BY_USER = '/api/user/:userId/organizations',
    ORGANIZATION_AREAS = '/api/organization/:organizationId/area',
    ORGANIZATION_AREA = '/api/organization/:organizationId/area/:areaId',
    ORGANIZATIONS_NB_USER = '/api/contract/:contractId/organizations/user',
    // USER
    USER_AREAS = "/api/user/:userId/area",
    USER_CONFIGURATION = '/api/user/portal/:portalCode/configuration',
    USER_GEOSHAPE = "/api/user/:userId/geoshape",
    USERS_NB_ORGANIZATION = '/api/contract/:contractId/users/organization',
    USER_SKILLS = '/api/user-skill-menu',
    USERS_WITH_ROLES = '/api/contract/:contractId/user/role',
    USERS = '/api/contract/:contractId/user',
    USER = '/api/user/:userId',
}

export enum VdmStatusMap {
    ONGOING = 'Enc' as any,
    PLANNED = 'Pla' as any,
    DONE = 'Rea' as any
}

export enum StatusMap {
    ONGOING = 'ongoing' as any,
    PLANNED = 'planned' as any,
    DONE = 'done' as any,
    ONGOING_SHORT = 'Enc' as any,
    PLANNED_SHORT = 'Pla' as any,
    DONE_SHORT = 'Rea' as any
}

export enum Tabs {
    DASHBOARD = 'dashboard',
    MAP = 'map',
}

export enum Interventions {
    ITV_COUNT_PER_PAGE = 9999
}

export enum ServiceMetadataField {
    GROUP = 'group',
    THEME = 'theme',
    URL = 'url',
}

export enum ReactGridLayout {
    MARGIN = 24,
    ROW_HEIGHT = 126,
    WIDTH_LG = 1200,
    WIDTH_MD = 768,
    WIDTH_XS = 0,
    COL_LG = 12,
    COL_MD = 6,
    COL_XS = 3,

}

export const ReactGridLayoutBreakpoints = {
    lg: ReactGridLayout.WIDTH_LG,
    md: ReactGridLayout.WIDTH_MD,
    xs: ReactGridLayout.WIDTH_XS,
}

export const ReactGridLayoutCols = {
    lg: ReactGridLayout.COL_LG,
    md: ReactGridLayout.COL_MD,
    xs: ReactGridLayout.COL_XS,
}

export const acceptedImageMimeTypes = [
    'image/png',
    'image/jpeg',
    'image/gif',
    'image/x-icon',
    'image/svg+xml',
    'image/tiff',
    'image/webp'
]

export const SERVICE_SIGNAL_OPERATOR="SIGNAL_OPERATOR";
export const SERVICE_REQUEST_OPERATOR="REQUEST_OPERATOR";
export const SERVICE_REQUEST="REQUEST";

export const AVAILABLES_COUNTRIES = [
    { value: 'FR', label: 'France' },
    { value: 'TN', label: 'Tunisie' }
]
export const DEFAULT_COUNTRY: string = 'France'

export const ITEM_TO_SHOW = 1;
export const NO_PAGINATION = false;

export const DAYS_IN_YEAR = 365
export const MONTHS_COUNT = 12
