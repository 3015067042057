import OnDijonSmallLogo from "../../assets/themes/ondijon/on_dijon_small.svg";
import OnDijonBigLogo from "../../assets/themes/ondijon/on_dijon_big.svg";
import VillagileLogo from "../../assets/themes/villagile/logo.svg";
import VillagileLogoSubtitled from "../../assets/themes/villagile/logo-subtitled.svg";

interface SiteConfiguration {
    squareLogo: string | null,
    rectangleLogo: string | null,
    squareLogoDarkMode: string | null,
    rectangleLogoDarkMode: string | null,
    primaryColor: string | null,
    primaryColorLight: string | null,
    primaryColorDark: string | null,
    secondaryColor: string | null,
    secondaryColorLight: string | null,
    secondaryColorDark: string | null,
    contrastTextColor: string | null,
    faviconOnglet: string | null,
}

interface SiteTheme {
    documentTitle: string,
    title: string,
    client: string,
    showLogo: {
        nav: boolean
    },
    squareLogoDefault: string,
    rectangleLogoDefault: string,
}

export type AppTheme = SiteTheme & SiteConfiguration;

export const AppThemes: {[key: string]: SiteTheme} = {
    onDijon: {
        documentTitle: 'themes.ondijon.documentTitle',
        title: 'themes.ondijon.title',
        client: 'themes.ondijon.client',
        showLogo: {
            nav: true
        },
        squareLogoDefault: OnDijonBigLogo as string,
        rectangleLogoDefault: OnDijonSmallLogo as string,
    },
    villagile: {
        documentTitle: 'themes.villagile.documentTitle',
        title: 'themes.villagile.title',
        client: 'themes.villagile.client',
        showLogo: {
            nav: false
        },
        squareLogoDefault: VillagileLogo as string,
        rectangleLogoDefault: VillagileLogoSubtitled as string,
    },
}
