import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import classnames from 'classnames';
import "./SzDropzone.scss";
import { acceptedImageMimeTypes } from "../../../constants";
import { SzIcon } from "@suezenv/react-theme-components";

type SzDropZoneProps = {
    placeholder: any,
    multiple: boolean,
    dropHandle: (fileList: any) => void,
    className?: string,
    maxFiles?: number,
    defaultFiles?: any[],
    accept?: string[],
    deleteHandle: (fileList: any) => void
}

export type arrayBuffer = {
    path: string;
    name: string;
    lastModified: number;
    size: number;
    type: string;
}

export const SzDropZone = (props: SzDropZoneProps) => {
    const {
        placeholder,
        multiple,
        dropHandle,
        className,
        maxFiles = null,
        defaultFiles = [],
        accept = [],
        deleteHandle
    } = props;

    const defaultImagePreview = typeof defaultFiles !== "undefined" && !!defaultFiles[0] ? defaultFiles[0] : '';
    const [state, setState] = useState<any>({ files: defaultFiles });
    const [imagePreview, setImagePreview] = useState<any>(defaultImagePreview);
    const onDrop = (files: any) => {
        let filesList: any = multiple ? [...state.files, ...files] : files;
        filesList = (maxFiles && filesList.length > maxFiles) ? filesList.slice(0, maxFiles) : filesList;
        setState({ ...state, files: filesList });
        dropHandle(filesList);
        const file = files[0];
        if (acceptedImageMimeTypes.indexOf(file.type) >= 0) {
            const reader = new FileReader();
            if ('undefined' !== typeof file) {
                reader.onloadend = () => {
                    setImagePreview(reader.result)
                }
                reader.readAsDataURL(file);
            }
        }
    }

    useEffect(() => {
        setImagePreview(defaultImagePreview);
    }, [defaultImagePreview])

    const disabled = false;
    const { getRootProps, getInputProps } = useDropzone({ multiple, onDrop, disabled, accept: accept.toString() });
    const deleteFileHandle = (index: number) => {
        const newFiles = [...state.files]
        newFiles.splice(index, 1);
        setState({ ...state, files: newFiles });
        deleteHandle(newFiles);
    }
    const deleteAllFiles = () => {
        setState({ ...state, files: [] })
        deleteHandle([]);
        setImagePreview("");
    }
    const files = state.files.map((file: any, index: number) => {
        // delete just not saved files
        const delLink = (file.id === undefined) ?
            <span className='delete-file' onClick={() => deleteFileHandle(index)}> X</span> : null;
        return (<li key={index}>
            {file.name} {delLink}
        </li>);
    });

    const fileRemover = () => {
        return <div className={"remove-file"} onClick={() => {
            deleteAllFiles();
        }}>
            <SzIcon icon={"add-circle"} variant={"line"}/>
        </div>
    }

    const classNames = classnames('dropzone', className)
    return (
        <>
            {maxFiles === 1 && imagePreview !== "" && fileRemover()}
            <div {...getRootProps({ className: classNames })}>
                <input {...getInputProps()} />
                <p className={"dropzone-box m-0"}>
                    {imagePreview !== "" ? <img className={"dropzone--preview-img"} src={imagePreview}
                                                alt={"upload_image"}/> : placeholder}
                </p>
            </div>
            {maxFiles !== 1 &&
            <ul className="m-3 p-2 file-list">{files}</ul>
            }
        </>
    );
}
