import React, { useEffect, useState } from 'react';
import "./SzDropzone.scss";
import { SzDropZone } from "./index";
import { SzIcon } from "@suezenv/react-theme-components";
import { acceptedImageMimeTypes } from "../../../constants";

export const ImgDropZone = (props: { onBase64Update: (base64: string, fileId?: string) => void, currentImage?: string | null }) => {
    const {
        onBase64Update,
        currentImage
    } = props;

    const [base64, setBase64] = useState<any>()

    const onDrop = (files: any) => {
        const file = Array.isArray(files) ? files[0] : files;
        if (acceptedImageMimeTypes.indexOf(file.type) >= 0) {
            const reader = new FileReader();
            if ('undefined' !== typeof file) {
                reader.onloadend = () => {
                    setBase64(reader.result)
                }
                reader.readAsDataURL(file);
            }
        }
    }

    useEffect(() => {
        if ('undefined' !== typeof base64) {
            onBase64Update(base64);
        }
    }, [base64])

    const onDelete = () => {
        setBase64("");
    }

    return <SzDropZone
        placeholder={<SzIcon icon={"download-bottom"} variant={"line"}/>}
        multiple={false}
        defaultFiles={[currentImage ?? '']}
        dropHandle={onDrop}
        deleteHandle={onDelete}
        accept={acceptedImageMimeTypes}
        maxFiles={1}
    />;
}

export default ImgDropZone;
