import React, { useEffect, useRef, useState } from "react";
import { ApplicationConfigurationFormProps, ExternalsServicesEntity } from "../../../../types";
import { SzCheckbox, SzInput, SzTypographie } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import AddButton from "components/elements/Button/AddButton";
import _ from "lodash";
import { getLabelCode, isTrue, isTrueAsString } from "helper/Common";
import ImgDropZone from "components/elements/SzDropzone/imgDropZone";
import SzDualListBox, { SzDualListBoxOption } from "components/elements/SzDualListBox";

export const serviceName = "EXTERNALS_SERVICES"

type ExternalsServiceList = ExternalsServicesEntity[]

type ExternalsServicesParameters = {
    services: ExternalsServiceList
}

const emptyExternalsServicesFormData: ExternalsServicesEntity = {
    label: "",
    url: "",
    categories: "",
    customConnect: null,
    metadata: null,
    isPortal: false,
    bannerLogo: "",
    bannerBackgroundColor: "",
    buttonTextColor: "",
    buttonBackgroundColor: "",
}

export const EXTERNALS_SERVICES = (props: ApplicationConfigurationFormProps) => {
    const { serviceConfigurations, setServiceConfigurations, contractServiceConfiguration, owner, ownerType } = props;
    const isOrganizationService = () => ownerType === "ORGANIZATION"

    // Organization
    const [dualOptions, setDualOptions] = useState<SzDualListBoxOption[]>([]);
    const [selectedDualOptions, setSelectedDualOptions] = useState<SzDualListBoxOption[]>([]);

    const { t } = useTranslation();
    const getFormId =(value: number): number => value
    const [formDisplayDataIndexes, setFormDisplayDataIndexes] = useState<number>(0)
    const [externalsServices, setExternalsServices] = useState<ExternalsServiceList>(
        isOrganizationService()
            ? []
            : serviceConfigurations[serviceName]?.parameters?.services ?? [emptyExternalsServicesFormData]
    )
    const parameters = useRef<ExternalsServicesParameters>({
        services: externalsServices
    })

    const handleImageUpload = (base64: string, formId: number) => {
        let formData: ExternalsServiceList = _.cloneDeep(externalsServices)
        formData[formId]['bannerLogo'] = base64

        setExternalsServices(formData)
    }

    const handleServiceFormChange = (event: any) => {
        let formName: string = event.target.name
        let matcher = formName.match(/^(.*)\.(.*)$/)
        let formId: number|undefined = matcher?.[1] as unknown as number
        let inputName: string|undefined = matcher?.[2]
        let formType = event.target.type
        let formData: ExternalsServiceList = _.cloneDeep(externalsServices)

        if (!formId || !inputName) {
            console.error(`ExternalsServices | FormId or InputName not found in formName ${formName}`)
            return
        }

        // @ts-ignore
        if (undefined === formData[formId] || (undefined === formData[formId][inputName])) {
            console.error(`ExternalsServices | FormData doesn't contains formId ${formId} or inputName ${inputName}`)
            return
        }

        // @ts-ignore
        formData[formId][inputName] =
            formType === "checkbox"
                ? !isTrue(event.target.value) // must be inverted for checkbox
                : event.target.value

        setExternalsServices(formData)
    }

    const displayForms = () => {
        let formsIds: number[] = _.range(0, formDisplayDataIndexes+1)

        return formsIds.map((value: number) => {
            let formId = getFormId(value)

            return <div key={formId} className="mb-3">
                <fieldset className="my-3 border-bottom">
                    <label className="pl-5">{t('admin.services.configuration.form.externalsServices.title')}</label>
                </fieldset>
                <div className="row">
                    <div className="col-6">
                        <SzInput
                            placeholder={t('admin.services.configuration.form.externalsServices.label')}
                            value={externalsServices[formId]?.label ?? ''}
                            name={`${formId}.label`}
                            onChange={handleServiceFormChange}
                            label={t('admin.services.configuration.form.externalsServices.label')}
                            required={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <SzInput
                            placeholder={'http://url'}
                            value={externalsServices[formId]?.url ?? ''}
                            name={`${formId}.url`}
                            onChange={handleServiceFormChange}
                            label={t('admin.services.configuration.form.externalsServices.url')}
                            required={true}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <SzInput
                            value={externalsServices[formId]?.categories ?? ''}
                            placeholder="1502,1505"
                            name={`${formId}.categories`}
                            onChange={handleServiceFormChange}
                            label={t('admin.services.configuration.form.externalsServices.categories')}
                            required={true}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <SzCheckbox
                            id={`${formId}.isPortal`}
                            key={`${formId}.isPortal`}
                            className="mb-1 mt-3"
                            value={isTrueAsString(externalsServices[formId]?.isPortal)}
                            checked={isTrue(externalsServices[formId]?.isPortal)}
                            name={`${formId}.isPortal`}
                            onChange={handleServiceFormChange}
                            type='checkbox'
                            label={t('admin.services.configuration.form.externalsServices.isPortal')}
                        />
                    </div>
                </div>

                {isTrue(externalsServices[formId]?.isPortal) ? <div>
                    <div className="row">
                        <div className="col">
                            <SzTypographie className="m-0 p-0" color="disabled" weight="bold">Logo</SzTypographie>
                            <ImgDropZone
                                onBase64Update={(base64: string) => handleImageUpload(base64, formId)}
                                currentImage={externalsServices[formId]['bannerLogo'] ?? null}
                            />
                        </div>
                        <div className="col">
                            <SzInput
                                placeholder="Application"
                                value={externalsServices[formId]?.customConnect ?? ''}
                                name={`${formId}.customConnect`}
                                onChange={handleServiceFormChange}
                                label={t('admin.services.configuration.form.externalsServices.customConnect')}
                                required={true}
                            />
                        </div> 
                    </div>

                    <div className="row">
                        <div className="col">
                            <SzInput
                                placeholder="#f0f0f0"
                                value={externalsServices[formId]?.bannerBackgroundColor ?? ''}
                                name={`${formId}.bannerBackgroundColor`}
                                onChange={handleServiceFormChange}
                                label={t('admin.services.configuration.form.externalsServices.bannerBackgroundColor')}
                                required={true}
                            />
                        </div>
                        <div className="col">
                            <SzInput
                                placeholder='{"login":"mylogin","password":"mypassword"}'
                                value={externalsServices[formId]?.metadata ?? ''}
                                name={`${formId}.metadata`}
                                onChange={handleServiceFormChange}
                                label={t('admin.services.configuration.form.externalsServices.metadata')}
                                required={true}
                            />
                        </div>
                    </div>
                </div> : null /* end isPortal */}
            </div>
        })
    }

    const addNewServiceForm = () => {
        let newFormIndex: number = formDisplayDataIndexes + 1
        let tmpFormData: ExternalsServiceList = _.cloneDeep(externalsServices)
        tmpFormData.push(emptyExternalsServicesFormData)
console.log(tmpFormData)
        setExternalsServices(tmpFormData)
        setFormDisplayDataIndexes(newFormIndex)
    }

    const handleAddButtonClick = () => {
        addNewServiceForm()
    }

    // Organization

    const onDualSelectedChange = (selected: SzDualListBoxOption[]) => {
        let selectedCodes = selected.map((item: SzDualListBoxOption) => item.code)
        let selectedContractServices: ExternalsServiceList = (contractServiceConfiguration?.parameters?.services ?? [])
            .filter((item: ExternalsServicesEntity) => selectedCodes.includes(getLabelCode(item.label)))

        setSelectedDualOptions(selected)
        setExternalsServices(selectedContractServices)
    }

    const getSelectedDualOptions = (): SzDualListBoxOption[] => {
        let contractServices: ExternalsServiceList = contractServiceConfiguration?.parameters?.services
        if (!contractServices) {
            return []
        }

        return contractServices.map((item: ExternalsServicesEntity) => {
            return {
                id: getLabelCode(item.label),
                label: item.label,
                code: getLabelCode(item.label)
            }
        })
    }

    const getFilteredSelectedDualOptions = (keyword: string): void => {
        setDualOptions(getSelectedDualOptions().filter(
            (item: SzDualListBoxOption) => item.label.toLowerCase().indexOf(keyword.toLowerCase()) !== -1
        ))
    }

    const displayOrganizationForm = () => {
        return <SzDualListBox
            options={dualOptions}
            selectedOptions={selectedDualOptions}
            showRowIcons={true}
            onlySelectWithRowIcons={true}
            leftSideTitle={t("admin.services.modal.add.allServices")}
            rightSideTitle={t("admin.services.modal.add.selectedServices")}
            onChange={onDualSelectedChange}
            isScrollable={true}
            onKeywordsChange={getFilteredSelectedDualOptions}
            searchPlaceholder={t('search')}
        />
    }

    useEffect(() => {
        let contractServices = contractServiceConfiguration?.parameters?.services
        if (contractServices) {
            setDualOptions(getSelectedDualOptions())
        }
    }, [contractServiceConfiguration?.parameters?.services])

    useEffect(() => {
        parameters.current = {...parameters.current, services: externalsServices }
        if (
            parameters.current.services !== serviceConfigurations[serviceName]?.parameters?.services
        ) {
            let validesServices: ExternalsServiceList = [] // Not empty one
            for (const currentConfiguration of parameters.current.services) {
                if (
                    currentConfiguration.label
                    && currentConfiguration.url
                    && currentConfiguration.categories
                ) {
                    validesServices.push(currentConfiguration)
                }
            }

            setServiceConfigurations({
                ...serviceConfigurations, [serviceName]: {
                    name: serviceName,
                    owner: owner,
                    ownerType: ownerType,
                    parameters: {
                        services: validesServices
                    }
                }
            })
        }
    }, [externalsServices])

    if (isOrganizationService()) {
        return <>{displayOrganizationForm()}</>
    }

    return <div>
        {displayForms()} 
        <div className="d-flex justify-content-center">
            <AddButton handleClick={handleAddButtonClick}/>
        </div>
    </div>
}
