import axiosService from './Request';
import { ApiUrls } from "../constants";
import { config } from "../config";
import { UserContract } from "../types";
import { store } from "../store";

export class DocumentService {
    public static getNotifications = () => {
        const url = ApiUrls.DOCUMENT_NOTIFICATIONS;
        return axiosService.get(url, false, true);
    }

    public static getLastCreatedDocuments = () => {
        const url = ApiUrls.LAST_DOCUMENTS;
        return axiosService.get(url, false, true);
    }

    public static getDocuments = () => {
        const url = ApiUrls.GET_DOCUMENTS;
        return axiosService.get(url, false, true);
    }

    /**
     * Generate mayan url according to current contract metadata.
     */
    public static getMayanUrl = (path: string = ''): string => {
        let generatedMayanUrl = '';

        try {
            const {contract}: UserContract = store.getState();
            const { contractMetadatas } = contract?.currentContract;
            const contractMetadataMayanUrl = contractMetadatas?.find(metadata => metadata.key === 'mayanUrl')?.value ?? '';
            const mayanUrl = contractMetadataMayanUrl !== '' ? contractMetadataMayanUrl : config.mayanUrl;
            const realm = contractMetadatas?.find(metadata => metadata.key === 'realm')?.value ?? '';
            let urlObject = new URL(path, mayanUrl);

            if (realm) {
                urlObject.searchParams.set('idp', realm);
            }

            generatedMayanUrl = urlObject.toString();
        } catch (error) {
            // remove double slash in the path
            path = `/${path}`.replace(/\/\//g, '/')
            generatedMayanUrl = `${config.mayanUrl}${path}`;
        }

        return generatedMayanUrl;
    }
}
