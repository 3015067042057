import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzAlert, SzButton, SzIcon, SzSpinner, SzTypographie } from "@suezenv/react-theme-components";
import * as action from '../../actions';
import { useTheme } from "../../hooks/theme";
import "./navigationDashboard.scss";
import defaultLogo from "../../assets/themes/villagile/Villagile_Produit_Blanc.svg"
import { SkillSetService } from "services/SkillSetService";
import { ExternalsServicesEntity } from "types";
import { getLabelCode, isEmptyArray } from "helper/Common";

/** 
 * @deprecated 
 * @todo remove it when Externals Services are available
 */
interface ILegacyService {
    code: string,
    description: string,
    iconCode: string,
    label: string,
    group: string,
    theme: string,
    url: string
}

interface IState {
    skillServices: any,
    showServices: boolean
}

const navigationDashboard = function NavigationDashboard(props: any) {
    const { closeMenuHandler } = props;
    const { skills } = props.skills
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(true)
    const [state, setState] = useState<IState>({
        skillServices: [],
        showServices: false,
    });
    const { t } = useTranslation();
    const { appTheme } = useTheme()!;

    const showServices = (skillServices: []) => {
        setState({ ...state, skillServices: skillServices, showServices: !state.showServices })
    }

    const isPortal = (service: ExternalsServicesEntity&ILegacyService) => {
        return (service.group === "portal") || (service.isPortal)
    }

    const getFilteredServices = (services: (ExternalsServicesEntity&ILegacyService)[]) => {
        return services .filter((service: ExternalsServicesEntity&ILegacyService) => !isPortal(service))
    }

    const getFilteredServicesPortal = (services: (ExternalsServicesEntity&ILegacyService)[]) => {
        return services .filter((service: ExternalsServicesEntity&ILegacyService) => isPortal(service))
    }

    const renderSkills = () => {
        let skillsRendered;

        if (error) {
            return <SzAlert variant="warning" transparent={true}>
                {t('alert.loading_error')}
            </SzAlert>
        }

        if (loading) {
            return <SzSpinner/>
        }

        if (skills) {
            skillsRendered = Object.entries(skills).map((skillServices: any, index: any) => {
                const skill: string = skillServices[0];

                return (
                    <li className='my-3' key={index}>
                        <SzButton
                            icon='color-drop-pick'
                            variant='secondary'
                            className='btn-arrowed'
                            onClick={() => showServices(skillServices)}
                        >
                            {t(`skills.${skill}`)}
                        </SzButton>
                    </li>
                );
            });
        } else {
            skillsRendered = <li>
                {t('skills.no_skills')}
            </li>
        }

        return <ul className='list-unstyled'>
            {skillsRendered}
        </ul>
    }

    const renderModules = () => {
        return (
            <nav className="d-flex flex-column nav-dashboard-modules shadow">
                <header className="px-3 d-flex align-items-center">
                    <div className='px-2'>
                        <img src={appTheme?.squareLogo ? appTheme.squareLogo : defaultLogo} className="icon" alt='logo'/>
                    </div>
                    <div className="border-left ml-4 pl-2">
                        <SzTypographie variant="h1" weight="regular">{t('navigation.portals')}</SzTypographie>
                        <SzTypographie variant="caption"
                                       weight="medium">{appTheme && t(appTheme.client)}</SzTypographie>
                    </div>
                </header>
                <div className="px-3 py-2 category-block">
                    {renderSkills()}
                </div>
            </nav>
        )
    }

    const renderServicePortal = (service: ExternalsServicesEntity) => {
        if (!service.url || !service.isPortal) {
            return <></>
        }

        const key = getLabelCode(service.label)
        const bannerStyle = service.bannerBackgroundColor ? { backgroundColor: service.bannerBackgroundColor } : null

        return (
            <div className={`portal-block theme-default`} key={`key${key}`}>
                <header className="d-flex align-items-center px-3" style={bannerStyle ? bannerStyle : undefined}>
                    <div className='px-2'>
                        <img src={`${service.bannerLogo}`} className='icon' alt={service.label}/>
                    </div>
                </header>
                <div className="p-3">
                    <SzTypographie variant='body' weight='bold' className='mb-0'>
                        {t('services.go_to_portal')}
                    </SzTypographie>
                    <SzTypographie variant='caption' className='d-flex align-items-center'>
                        <SzIcon icon='layout-dashboard' variant='line' className='icon-tiny mr-2'/>
                        {t('services.access_portal')}
                    </SzTypographie>
                    <div className='mt-4 text-center'>
                        <SzButton
                            icon='data-file-bars-info'
                            className={'rounded-2'}
                            onClick={() => { window.open(service.url, '_blank') }}
                            variant="primary"
                        >
                            {t('services.access', { 'label': service.label })}
                        </SzButton>
                    </div>
                </div>
            </div>
        );
    }

    const renderLegacyPortal = (service: ILegacyService) => {
        const { iconCode, label, theme, url } = service;

        return <div className={`portal-block ${theme}`} key={`key${iconCode}`}>
                <header className="d-flex align-items-center px-3">
                    <div className='px-2'>
                        <img src={`/img/services/${iconCode}`} className='icon' alt={label}/>
                    </div>
                </header>
                <div className="p-3">
                    <SzTypographie variant='body' weight='bold' className='mb-0'>
                        {t('services.go_to_portal')}
                    </SzTypographie>
                    <SzTypographie variant='caption' className='d-flex align-items-center'>
                        <SzIcon icon='layout-dashboard' variant='line' className='icon-tiny mr-2'/>
                        {t('services.access_portal')}
                    </SzTypographie>
                    <div className='mt-4 text-center'>
                        <SzButton icon='data-file-bars-info'
                                  onClick={() => {
                                      window.open(url, '_blank');
                                  }}
                        >
                            {t('services.access', { 'label': label })}
                        </SzButton>
                    </div>
                </div>
            </div>
    }

    const renderServiceExternalTool = (service: { label: string, url: string}) => {
        const { label, url } = service;

        if (!url) {
            return <></>
        }

            return <SzButton
                variant={"tertiary"}
                icon={"arrow-right"}
                alignIcon={"right"}
                onClick={() => window.open(url, '_blank')}
            >
                {label}
            </SzButton>
    }

    const renderPortalService = (services: (ExternalsServicesEntity&ILegacyService)[]) => 
        getFilteredServicesPortal(services)
            .map((service: ExternalsServicesEntity&ILegacyService) => 
                (service.iconCode !== undefined)
                    ? renderLegacyPortal(service as ILegacyService)
                    : renderServicePortal(service as ExternalsServicesEntity)
    )

    const renderExternalToolsService = (services: (ExternalsServicesEntity&ILegacyService)[]) => {
        let notPortalServices = getFilteredServices(services)
        if (notPortalServices.length > 0) {
            return <div className="p-3">
                <SzTypographie variant='body' weight='bold' className='mb-0'>
                    {t('services.go_to_external_tool')}
                </SzTypographie>
                <SzTypographie variant='caption' className='d-flex align-items-center'>
                    <SzIcon icon='professions-man-construction-1' variant='line' className='icon-tiny mr-2'/>
                    {t('services.access_tool')}
                </SzTypographie>
                <div className='mt-4 d-flex flex-column align-items-end'>
                    {notPortalServices.map((service: ExternalsServicesEntity&ILegacyService) => renderServiceExternalTool(service))}
                </div>
            </div>
        }

        return <></>
    }

    const renderServices = () => {
        const { skillServices } = state;

        if (!skillServices) {
            return <></>
        }

        const skill = skillServices[0];
        const services = skillServices[1];
        const servicesPortal = getFilteredServicesPortal(services)

        return (
            <nav className='d-flex flex-column nav-dashboard-services'>
                {
                    isEmptyArray(servicesPortal) &&
                        <div className={`portal-block`}>
                            <header className="d-flex align-items-center px-3">
                                <div className='px-2'></div>
                            </header>
                        </div>
                }
                {renderPortalService(services)}
                {renderExternalToolsService(services)}
                <footer className='mt-auto'>
                    <SzTypographie variant='caption'>
                        {t('perimeter')} {t(`skills.${skill}`)}
                    </SzTypographie>
                </footer>
            </nav>
        )
    }

    useEffect(() => {
        SkillSetService.initUserSkills();
    }, [])

    useEffect(() => {
        if (skills) {
            if (skills.error) {
                setError(true);
            }
            setLoading(false);
        }
    }, [skills]);

    return (
        <>
            <div className="nav-dashboard-overlay" onClick={closeMenuHandler}/>
            {renderModules()}
            {state.showServices && renderServices()}
        </>
    );
}

const mapStateToProps = (state: any) => ({
    user: state.user.info,
    skills: state.skills
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        showModal: (tab: string) => dispatch(action.showModal(tab)),
        hideModal: () => dispatch(action.hideModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(navigationDashboard);
