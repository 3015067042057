import React, { useState } from "react";
import { SzAccordion, SzButton, SzTypographie } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import { ApplicationConfiguration, OwnerType } from "../../../../types";
import Switch from "react-switch";
import { serviceFormComponents } from '../configurationForm';
import { serviceName as EXTERNALS_SERVICES_NAME } from "../configurationForm/EXTERNALS_SERVICES"

type PropsType = {
    contractId: string
    ownerId: string,
    selectedServices: string[],
    ownerType: OwnerType,
    serviceConfigurations: { [key: string]: any },
    setServiceConfigurations: (serviceConfigurations: { [key: string]: ApplicationConfiguration }) => void,
    goToPrev: () => void,
    onValidate: () => void,
    isSaving: boolean,
    parentConfigurations?: { [serviceCode: string]: string },
    contractConfigurations?: ApplicationConfiguration[]
}

const Step2 = (props: PropsType) => {
    const {
        contractId,
        selectedServices,
        serviceConfigurations,
        setServiceConfigurations,
        ownerId,
        ownerType,
        goToPrev,
        onValidate,
        isSaving,
        parentConfigurations,
        contractConfigurations
    } = props;
    const existingServiceForms = Object.keys(serviceFormComponents)
    const { t } = useTranslation();
    const [activeKey, setActiveKey] = useState<string>("-1");

    const onAccordionClick = (key: string) => setActiveKey(key !== activeKey ? key : "-1");

    const onAccordionSwitchClick = (serviceName: string) => {
        let tmpConf: ApplicationConfiguration = serviceConfigurations[serviceName];

        if (ownerType === "CONTRACT") {
            tmpConf.forceChildConfiguration = !tmpConf.forceChildConfiguration;
        }
        if (ownerType === "ORGANIZATION") {
            tmpConf.useParentConfiguration = !tmpConf.useParentConfiguration;
            if (tmpConf.useParentConfiguration && parentConfigurations && typeof parentConfigurations[serviceName] !== "undefined") {
                tmpConf.parentConfiguration = parentConfigurations[serviceName];
            } else {
                delete tmpConf.parentConfiguration;
            }
        }

        setServiceConfigurations({ ...serviceConfigurations, [serviceName]: tmpConf });
    }

    const getFormComponent = (serviceName: string) => {
        if (existingServiceForms.indexOf(serviceName) !== -1) {
            const FormComponent = serviceFormComponents[serviceName as keyof typeof serviceFormComponents];

            let contractCurrentConfiguration: ApplicationConfiguration | undefined
            if (ownerType === "ORGANIZATION" && contractConfigurations) {
                contractCurrentConfiguration = 
                    contractConfigurations.filter((configuration: ApplicationConfiguration) => configuration.name === serviceName)[0]
                    ?? undefined
            }

            return <FormComponent
                contractId={contractId}
                owner={ownerId}
                ownerType={ownerType as OwnerType}
                serviceConfigurations={serviceConfigurations}
                setServiceConfigurations={setServiceConfigurations}
                contractServiceConfiguration={contractCurrentConfiguration}
            />
        }
        return null
    }

    const generateAccordionHeader = (serviceName: string,) => <div className={"d-flex justify-content-between px-2"}>
        <SzTypographie variant={"h2"} className={"pr-4"}>
            {t(`admin.services.configuration.form.${serviceName}`)}
        </SzTypographie>
        {ownerType === "CONTRACT"
            && typeof serviceConfigurations[serviceName] !== "undefined"
            && serviceConfigurations[serviceName].displayButtonForceChildConfiguration !== false
            && serviceName !== EXTERNALS_SERVICES_NAME
            && <div className={"d-flex"}>
            <Switch checked={serviceConfigurations[serviceName].forceChildConfiguration ?? false}
                    onChange={() => onAccordionSwitchClick(serviceName)} />
            <SzTypographie variant={"body"} className={"pl-2"}>
                {t(`admin.services.configuration.form.forceChildConfiguration`)}
            </SzTypographie>
            </div>
        }
        {ownerType === "ORGANIZATION"
        && typeof serviceConfigurations[serviceName] !== "undefined"
        && typeof parentConfigurations !== "undefined"
        && typeof parentConfigurations[serviceName] !== "undefined"
        && serviceName !== EXTERNALS_SERVICES_NAME
        && <div className={"d-flex"}>
            <Switch checked={serviceConfigurations[serviceName].useParentConfiguration ?? false}
                    onChange={() => onAccordionSwitchClick(serviceName)}/>
            <SzTypographie variant={"body"} className={"pl-2"}>
                {t(`admin.services.configuration.form.useParentConfiguration`)}
            </SzTypographie>
        </div>
        }
    </div>;

    const formsRendered = selectedServices.map((serviceName: string, index: number) => {
        const ServiceForm = getFormComponent(serviceName);
        if (ServiceForm) {
            const header = generateAccordionHeader(serviceName)
            const form = <div key={index} className={"p-4"}>
                {ServiceForm}
            </div>;

            return <SzAccordion.SzCollapse
                key={index}
                header={header}
                isDisabled={typeof serviceConfigurations[serviceName] !== "undefined" && (serviceConfigurations[serviceName].useParentConfiguration === true || serviceConfigurations[serviceName].forceChildConfiguration === true)}
                eventKey={index.toString()}
                onClick={() => onAccordionClick(index.toString())}
                body={form}
                message={t('admin.services.configuration.form.formDisabled')}
            />
        }
    });

    const noFormComponent = <div className={"p-5"}>
        <SzTypographie>
            {t('admin.services.configuration.form.noForm')}
        </SzTypographie>
    </div>

    return <>
        <SzAccordion activeKey={activeKey}>
            {formsRendered.filter((n) => n).length > 0 ? formsRendered : noFormComponent}
        </SzAccordion>
        <div className={"d-flex justify-content-between p-2"}>
            <SzButton onClick={goToPrev}>
                {t('previous')}
            </SzButton>
            <SzButton onClick={onValidate} loader={isSaving} isDisabled={isSaving}>
                {t('validate')}
            </SzButton>
        </div>
    </>;
}

export default Step2;
