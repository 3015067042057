import React, { useEffect, useState } from "react";
import { Themes } from "../../constants";
import { AppTheme, AppThemes } from "./themes";
import { useTranslation } from "react-i18next";
import { ContractsService } from "../../services/ContractsService";
import { Contract } from "../../types";
import { connect } from "react-redux";
import { useQuery } from "react-query";

type ThemeContextType = {
    appTheme: AppTheme | null,
    theme: string;
    setTheme: (value: string) => void;
};

type Props = {
    children: React.ReactNode,
    currentContract: Contract
};

export const ThemeContext = React.createContext<ThemeContextType | undefined>(undefined);

const ThemeProvider = ({ children, currentContract }: Props) => {
    const [theme, setTheme] = useState(Themes.VILLAGILE as string);
    const [appTheme, setAppTheme] = useState<AppTheme | null>(null);
    const { t } = useTranslation();

    const fetchSiteConfiguration = () => ContractsService.getSiteConfiguration(currentContract.id);
    const {
        data: siteConfiguration,
        isSuccess
    } = useQuery(['siteConfiguration', currentContract?.id], fetchSiteConfiguration, {
        enabled: !!currentContract,
        retry: 1
    });

    useEffect(() => {
        isSuccess && setAppTheme({...siteConfiguration?.data });
    }, [siteConfiguration]);

    useEffect(() => {
        if (appTheme) {
            document.body.className = theme;
            document.title = t(appTheme.documentTitle || AppThemes[theme].documentTitle);
            const link = document.querySelector("link#dynamic-favicon") as HTMLLinkElement;
            if (link) {
                link.href = appTheme.faviconOnglet || '';
            }
        }
    }, [appTheme]);

    return (
        <ThemeContext.Provider value={{ appTheme, theme, setTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

const mapStateToProps = (state: { contract: { currentContract: Contract } }) => ({
    currentContract: state.contract.currentContract
});

export default connect(mapStateToProps)(ThemeProvider);
