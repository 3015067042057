import { EXTERNALS_SERVICES }                       from './EXTERNALS_SERVICES';
import { KADRAN_PORTAL }                            from './KADRAN_PORTAL';
import { ODIVEA_PORTAL }                            from './ODIVEA_PORTAL';
import { PHAREENLIGNE_PORTAL }                      from './PHAREENLIGNE_PORTAL';
import { REPORT_TRENDS }                            from './REPORT_TRENDS';
import { POWER_BI }                                 from './POWER_BI';
import { REQUEST_RT_PROX_CITE }                     from './REQUEST_RT_PROX_CITE';
import { SIGNAL_NOTIFY }                            from './SIGNAL_NOTIFY';
import { TSMD_PORTAL }                              from './TSMD_PORTAL';
import { TSMS_PORTAL }                              from './TSMS_PORTAL';
import { VA_EXTERNAL_BAIGNADE_WATER_QUALITY }       from './VA_EXTERNAL_BAIGNADE_WATER_QUALITY';
import { VA_EXTERNAL_SERAMM_DOCUMENTATION_WORKS }   from './VA_EXTERNAL_SERAMM_DOCUMENTATION_WORKS';
import { VA_EXTERNAL_SERAMM_MAIL_MANAGEMENT }       from './VA_EXTERNAL_SERAMM_MAIL_MANAGEMENT';
import { VA_EXTERNAL_SERAMM_SITE_PUBLICATION }      from './VA_EXTERNAL_SERAMM_SITE_PUBLICATION';
import { VA_EXTERNAL_SIG_SERAMM }                   from './VA_EXTERNAL_SIG_SERAMM';
import { VA_EXTERNAL_SITE_AQUACALC }                from './VA_EXTERNAL_SITE_AQUACALC';
import { VA_EXTERNAL_SITE_AQUADVANCED_PLANT }       from './VA_EXTERNAL_SITE_AQUADVANCED_PLANT';
import { VA_EXTERNAL_SITE_AQUADVANCED_UD }          from './VA_EXTERNAL_SITE_AQUADVANCED_UD';
import { VA_EXTERNAL_SITE_AQUADVANCED_WW }          from './VA_EXTERNAL_SITE_AQUADVANCED_WW';
import { VA_EXTERNAL_SITE_AQUADVANCED_WN }          from './VA_EXTERNAL_SITE_AQUADVANCED_WN';
import { VA_EXTERNAL_SITE_GMAO_SERAMM }             from './VA_EXTERNAL_SITE_GMAO_SERAMM';
import { VA_EXTERNAL_SITE_MYSIG }                   from './VA_EXTERNAL_SITE_MYSIG';
import { VA_EXTERNAL_SITE_PANACHE }                 from './VA_EXTERNAL_SITE_PANACHE';
import { VA_EXTERNAL_SITE_SERAMM_AQUADVANCED_UD }   from './VA_EXTERNAL_SITE_SERAMM_AQUADVANCED_UD';
import { VA_EXTERNAL_SITE_SERAMM_INFOPED }          from './VA_EXTERNAL_SITE_SERAMM_INFOPED';
import { VA_EXTERNAL_SITE_SHAREPOINT_KADRAN }       from './VA_EXTERNAL_SITE_SHAREPOINT_KADRAN';
import { VA_EXTERNAL_SITE_SIGRENEA }                from './VA_EXTERNAL_SITE_SIGRENEA';
import { VA_EXTERNAL_SITE_TOPKAPI_KADRAN_EU }       from './VA_EXTERNAL_SITE_TOPKAPI_KADRAN_EU';
import { VA_EXTERNAL_SITE_TOPKAPI_KADRAN_EP }       from './VA_EXTERNAL_SITE_TOPKAPI_KADRAN_EP';
import { VA_EXTERNAL_SYNOPTIQUE_UDB }               from './VA_EXTERNAL_SYNOPTIQUE_UDB';
import { VA_EXTERNAL_SYNOPTIQUE_UDE }               from './VA_EXTERNAL_SYNOPTIQUE_UDE';

export const serviceFormComponents = {
    EXTERNALS_SERVICES: EXTERNALS_SERVICES,
    KADRAN_PORTAL: KADRAN_PORTAL,
    ODIVEA_PORTAL: ODIVEA_PORTAL,
    PHAREENLIGNE_PORTAL: PHAREENLIGNE_PORTAL,
    POWER_BI: POWER_BI,
    REPORT_TRENDS: REPORT_TRENDS,
    REQUEST_RT_PROX_CITE: REQUEST_RT_PROX_CITE,
    SIGNAL_NOTIFY: SIGNAL_NOTIFY,
    TSMD_PORTAL: TSMD_PORTAL,
    TSMS_PORTAL: TSMS_PORTAL,
    VA_EXTERNAL_BAIGNADE_WATER_QUALITY: VA_EXTERNAL_BAIGNADE_WATER_QUALITY,
    VA_EXTERNAL_SERAMM_DOCUMENTATION_WORKS: VA_EXTERNAL_SERAMM_DOCUMENTATION_WORKS,
    VA_EXTERNAL_SERAMM_MAIL_MANAGEMENT: VA_EXTERNAL_SERAMM_MAIL_MANAGEMENT,
    VA_EXTERNAL_SERAMM_SITE_PUBLICATION: VA_EXTERNAL_SERAMM_SITE_PUBLICATION,
    VA_EXTERNAL_SIG_SERAMM: VA_EXTERNAL_SIG_SERAMM,
    VA_EXTERNAL_SITE_SIGRENEA: VA_EXTERNAL_SITE_SIGRENEA,
    VA_EXTERNAL_SITE_MYSIG: VA_EXTERNAL_SITE_MYSIG,
    VA_EXTERNAL_SITE_AQUACALC: VA_EXTERNAL_SITE_AQUACALC,
    VA_EXTERNAL_SITE_AQUADVANCED_PLANT: VA_EXTERNAL_SITE_AQUADVANCED_PLANT,
    VA_EXTERNAL_SITE_AQUADVANCED_UD: VA_EXTERNAL_SITE_AQUADVANCED_UD,
    VA_EXTERNAL_SITE_AQUADVANCED_WW: VA_EXTERNAL_SITE_AQUADVANCED_WW,
    VA_EXTERNAL_SITE_AQUADVANCED_WN: VA_EXTERNAL_SITE_AQUADVANCED_WN,
    VA_EXTERNAL_SITE_GMAO_SERAMM : VA_EXTERNAL_SITE_GMAO_SERAMM,
    VA_EXTERNAL_SITE_PANACHE : VA_EXTERNAL_SITE_PANACHE,
    VA_EXTERNAL_SITE_SERAMM_AQUADVANCED_UD : VA_EXTERNAL_SITE_SERAMM_AQUADVANCED_UD,
    VA_EXTERNAL_SITE_SERAMM_INFOPED : VA_EXTERNAL_SITE_SERAMM_INFOPED,
    VA_EXTERNAL_SITE_SHAREPOINT_KADRAN : VA_EXTERNAL_SITE_SHAREPOINT_KADRAN,
    VA_EXTERNAL_SITE_TOPKAPI_KADRAN_EP: VA_EXTERNAL_SITE_TOPKAPI_KADRAN_EP,
    VA_EXTERNAL_SITE_TOPKAPI_KADRAN_EU: VA_EXTERNAL_SITE_TOPKAPI_KADRAN_EU,
    VA_EXTERNAL_SYNOPTIQUE_UDB: VA_EXTERNAL_SYNOPTIQUE_UDB,
    VA_EXTERNAL_SYNOPTIQUE_UDE: VA_EXTERNAL_SYNOPTIQUE_UDE,
};
