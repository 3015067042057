import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import {
    SzAlert,
    SzIcon,
    SzPagination,
    SzSpinner,
    SzTable
} from "@suezenv/react-theme-components";
import { Contract, Pagination, UserContract } from "../../../types";
import TabHeader from "../adminTabs/tabHeader";
import { connect } from "react-redux";
import { displayMessageIfEmptyTab } from 'components/admin/adminTabs/common';
import { ContractsService } from 'services/ContractsService';
import { UserConfigurationService } from 'services/UserConfigurationService';
import { store } from 'store';
import { currentContract } from 'actions';
import { CommonHelper } from 'helper/Common';
import { Constants } from '../../../constants';

type AdminUserContractsProps = {
    currentContractState: Contract,
}

const AdminUserContracts = (props: AdminUserContractsProps) => {
    const { t } = useTranslation();
    const { currentContractState } = props;
    const [userContractsData, setContractsData] = useState<UserContract[]>();
    const [pagination, setPagination] = useState<Pagination>();
    const [currentPage, setCurrentPage] = useState(Constants.DEFAULT_CURRENT_PAGE as number);

    const fetchContractsByUser = () => ContractsService.getContractsByUser(currentPage, Constants.DEFAULT_PER_PAGE);
    const {
        data: userContracts,
        isLoading,
        isError,
        isSuccess
    } = useQuery(['fetchContractsByUser', currentPage], fetchContractsByUser);

    const columns = [
        {
            dataField: 'label',
            text: t('admin.users.tabs.contracts.label')
        },
        {
            dataField: 'status',
            text: t('admin.users.tabs.contracts.status')
        },
        {
            dataField: 'action',
            text: t('admin.users.tabs.contracts.set_current')
        }
    ];

    const setAsCurrentContractAction = (contractToUse: UserContract, isCurrent: boolean) => {
        return isCurrent
            ? <SzIcon variant="line" className="mr-3 text-success" icon="check-circle-1"/>
            : <SzIcon onClick={() => { 
                store.dispatch(currentContract(contractToUse))
                UserConfigurationService.storeCurrentContractConfiguration(contractToUse, () => { window.location.assign('/') })
            }} variant='line' icon='keyboard-arrow-right' className="color-primary"/>
    }

    const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber);

    const renderPagination = (pagination: Pagination) => {
        return <div className="d-flex justify-content-center my-4">
            <SzPagination
                totalItemsCount={pagination.totalCount}
                activePage={currentPage}
                onChange={handlePageChange}
                itemsCountPerPage={pagination.perPage}
                pageRangeDisplayed={Constants.USERS_PAGINATION_PAGE_RANGE as number}
            />
        </div>;
    }

    useEffect(() => {
        if (isSuccess) {
            const contracts: UserContract[] = userContracts?.data ?? [];

            let contractList = contracts.map((contract: UserContract) => ({
                'id': contract.id,
                'label': contract.label,
                'status': contract.status,
                'isCurrent': contract.id === currentContractState.id,
                'action': setAsCurrentContractAction(contract, contract.id === currentContractState.id)
            }));

            contractList.sort((a, b) => b.isCurrent - a.isCurrent);

            setContractsData(contractList);
            setPagination(CommonHelper.getPagination(userContracts?.data?.length, userContracts?.headers));
        }
    }, [userContracts, currentContractState]);

    if (isError) {
        return <SzAlert
            variant="warning"
            transparent={true}
            className="w-100 border-0 flex-column p-4"
        >
            {t('alert.loading_error')}
        </SzAlert>
    }

    if (isLoading) {
        return <div className='p-4 text-center'>
            <SzSpinner/>
        </div>
    }

    return <div className="box">
        <TabHeader title={t('admin.users.tabs.contractsTitle')}
                   subtitle={t("admin.users.tabs.contractsSubtitle", { count: pagination?.totalCount })} 
        />
        {userContractsData && <SzTable
            data={userContractsData}
            keyField={'label'}
            columns={columns}
        />}
        {displayMessageIfEmptyTab(userContracts, t)}
        {pagination && renderPagination(pagination)}
    </div>
}

const mapStateToProps = (state: { contract: { currentContract: Contract } }) => ({
    currentContractState: state.contract.currentContract
});

export default connect(mapStateToProps)(AdminUserContracts);
